<template>
  <div>
    <van-cell :title="PatientName" is-link to="/FCardL" value="切换用户">
      <!-- 使用 title 插槽来自定义标题 -->
      <van-icon
        slot="right-icon"
        class="iconfont"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
      />
    </van-cell>
    <van-list v-model="loading" :finished="finished">
      <van-cell title="待缴费订单">
        <template slot="default">
          总金额：
          <span style="color: #57c4b7">¥{{ FeeSum }}</span>
        </template>
      </van-cell>
      <van-cell-group v-for="item in lists" :key="item + 'payl'">
        <van-cell v-bind="item" @click="onclickOrder(item.regNo, item.seqNos)">
          <!-- <van-cell v-bind="item"> -->
          <template slot="title">
            <span style="font-size: 16px">{{ item.deptName }}</span>
            <span style="color: #57c4b7">|</span>
            <span style="font-size: 12px">{{ item.drName }}</span>
            <span style="color: #57c4b7">|</span>
            <span style="color: red; font-size: 12px">未支付</span>
          </template>
          <template slot="label">
            <span style="font-size: 14px">就诊时间：</span>
            <span style="color: #57c4b7; font-size: 14px">{{
              item.operTime
            }}</span>
          </template>
          <template slot="right-icon">
            <span style="color: #57c4b7; text-align: center">
              <p>¥{{ item.totalFee }}</p>
              <!-- <van-button
                  round
                  :disabled="disabled"
                  size="small"
                  style="
                    background: #57c4b7;
                    display: block;
                    color: white;
                    float: right;
                  "
                  text="立即支付"
                  @click="onToast"
                /> -->
              <van-button
                round
                :disabled="disabled"
                size="small"
                style="
                  background: #57c4b7;
                  display: block;
                  color: white;
                  float: right;
                "
                text="立即支付"
                @click="
                  onclickPay(
                    item.regNo,
                    item.deptName,
                    item.drName,
                    item.recipes,
                    item.totalFee,
                    item.content
                  )
                "
              />
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div
      style="
        margin: 20px 0 25px 0;
        font-size: 14px;
        width: 100%;
        text-align: center;
        color: #969799;
      "
      v-if="!lists"
    >
      目前没有代缴费订单哦！
    </div>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import appconst from "../../lib/appconst";
import store from "../../store";
import ajax from "../../lib/ajax";
const OperatorId = "100002";
var listss = [];
let orderid = "";
// let sum = 0;
export default {
  data() {
    return {
      PatientName: "",
      CardNumber: "",
      PayStatus: "",
      patientid: "",
      Idcard: "",
      FeeSum: 0,
      card: "",
      disabled: false,
      lists: [],
      payArg: {},
      loading: false,
      finished: false,
      wxPayInfo: {
        timestamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
    };
  },
  created() {
    this.PatientName = store.getters["store/userName"];
    this.CardNumber = store.getters["store/cardNo"]; //声明就诊卡号
    this.patientid = store.getters["store/patientid"]; //声明病例号
    this.onLoad();
  },
  methods: {
    isWeixin() {
      return /MicroMessenger/gi.test(navigator.userAgent);
    },
    initWxConfig() {
      // 初始化微信JS功能配置
      let me = this;
      return new Promise((resolve, reject) => {
        me.getWxjsSign(appconst.wechatAppId)
          .then((x) => {
            // alert(appconst.wechatAppId);
            var config = {
              appId: appconst.wechatAppId,
              timestamp: x["timestamp"],
              nonceStr: x["noncestr"],
              signature: x["sign"],
              ticket: x["ticket"],
            };
            me.wxconfig = Object.assign(me.wxconfig, config);
            wx.ready((x) => {
              if (x) {
                //
              }
              resolve(me.wxconfig);
            });
            wx.error((err) => {
              if (err) {
                //
              }
              reject(x);
            });
            wx.config(me.wxconfig);
            // alert(wx);
          })
          .catch((x) => {
            reject(x);
          });
      });
    },
    // 获取微信JS签名
    getWxjsSign() {
      let url = encodeURIComponent(location.href);
      var center = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/JsSign`;
      return new Promise((resolve, reject) => {
        ajax
          .get(center, {
            params: {
              appid: appconst.wechatAppId,
              url: url,
            },
          })
          .then((x) => {
            resolve(x.data.result);
            console.log("测试" + x.data.result);
          })
          .catch((x) => {
            reject(x);
            console.log(x);
          });
      });
    },
    async onLoad() {
      let me = this;
      if (me.isWeixin()) {
        me.initWxConfig();
        wx.hideOptionMenu(); // 禁用分享

        // TODO：加载订单信息用于显示
      } else {
        Toast.fail({ message: "请在微信中打开此页面", duration: 0 });
      }
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.lists.push(this.lists.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.lists.length == this.lists.length) {
          this.finished = true;
        }
      }, 500);
      Toast.loading({
        message: "数据获取中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let outpatientId = store.getters["store/patientid"];
      // outpatientId = "0001052852";
      await ajax
        .post("/Api/FeeService/GetFeeList", {
          userID: outpatientId,
          payStatus: 0,
          operatorId: "100002",
          payWay: 3,
          machineName: "wechat",
        })
        .then((rep) => {
          console.log(rep.data.result);
          listss = rep.data.result.clinicFees;
          this.payArg = rep.data.result.payArgs;
          if (listss.length != 0) {
            this.lists = listss;
            console.log(this.lists);
            for (let i = 0; i < listss.length; i++) {
              const el = listss[i].totalFee;
              this.FeeSum += el;
              // console.log(sum);
            }
            Toast.clear();
          } else {
            Toast("暂无可缴费订单，请稍后再试");
          }
        })
        .catch((err) => {
          console.log(err.message);
          Toast("获取缴费列表异常：" + err.message);
        });
    },
    // 查看处方详细信息
    onclickOrder(regNo, seqNos) {
      store.commit("store/setRegNo", regNo);
      store.commit("store/setSeqNo", seqNos);
      this.$router
        .replace({
          //path:'/Ordei/:OrderId',
          path: "/OrdeI",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 支付
    async onclickPay(regNo, deptName, drName, recipes, sumfee, content) {
      window.event.stopPropagation();
      this.disabled = true;
      Toast.loading({
        message: "正在处理中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let me = this;
      // 将要进行支付前 拟定订单
      let openId = store.getters["wechat/openid"];
      let idcard = store.getters["store/idcard"];
      let outpatientId = store.getters["store/patientid"];
      // outpatientId = "0001052852";
      let phone = store.getters["store/phone"];
      var userId = store.getters["store/userId"];
      let userName = store.getters["store/userName"];
      for (let i = 0; i < recipes.length; i++) {
        const element = recipes[i];
        recipes[i].repNoList = element.recipeNO;
        recipes[i].fee = element.totalCost;
      }
      // console.log(itemName);
      ajax
        .post("/Api/PayService/NewOrder", {
          userID: outpatientId, //病历号
          userName: userName, //用户名
          idCard: idcard,
          phone: phone,
          hasCard: false,
          cardNo: this.CardNumber,
          title: "门诊缴费",
          content: content,
          // content:depname+'|'+dorname+'|'+regLevel+'|'+0.01,
          description: `微信公众号支付|${this.PatientName}|${sumfee}`,
          // description:"微信公众号支付"+'|'+userName+'|'+0.01,
          // cost:0.01,
          cost: sumfee,
          machineName: "wechat",
          wxOpenID: openId,
        })
        .then(async (res) => {
          orderid = res.data.result;
          // alert(orderid);
          if (orderid > 0) {
            await me.getWxPayInfo();
            await ajax
              .post("/Api/WechatAgentService/AgentFeePaid", {
                // orderId: orderid,
                // regNo: regNo,
                // seqNos: seqNos,
                // termialType: null,
                // payWay: 3,
                // cardPayData: null,
                // hisFromDept: deptName,
                // hisFromDoctor: drName,
                // hisFromCreateTime: operTime,
                // operatorId: OperatorId,
                // machineName: "wechat",
                // patientID: userId,
                // openID: openId,
                // idCard: idcard,
                // userName: this.PatientName,
                // hisFromDept: deptName,
                // hisFromDoctor: drName,
                // hisFromCreateTime: operTime,
                orderId: orderid,
                list: recipes,
                patientID: userId,
                registerNO: regNo, //挂号流水号
                cardTypeID: 4,
                cardNO: idcard,
                payType: {
                  payTypeID: 3,
                  payModeName: "微信",
                  // openBank: "string",
                  // payCardNo: "string",
                  // posTransNo: "string",
                  amount: sumfee,
                },
                operatorId: OperatorId,
                payWay: 3,
                machineName: "wechat",

                // position: "",
                // orderId: orderid,
                // termialType: null,
                // regNo: regNo,
                // payArg: this.payArg[regNo],
                // payWay: "微信",
                // cardPayData: null,
              })
              .then(async (res) => {
                if (res.data.result === 0 || res.data.result === 2) {
                  Toast.clear();
                  wx.chooseWXPay({
                    // appId: me.wxPayInfo.appId,
                    timestamp: me.wxPayInfo.timestamp,
                    nonceStr: me.wxPayInfo.nonceStr,
                    package: me.wxPayInfo.package,
                    signType: me.wxPayInfo.signType,
                    paySign: me.wxPayInfo.paySign,
                    success: async function (res) {
                      let resop = res.errMsg;
                      Toast(resop);
                      Toast(orderid);
                      if (resop === "chooseWXPay:ok") {
                        //更新订单
                        // store.commit("store/setOrderId",orderid);
                        // sessionStorage.setItem("orderid", orderid);
                        // sessionStorage.setItem("regNo", regNo);
                        // sessionStorage.setItem("seqNos", seqNos);
                        // sessionStorage.setItem("deptName", deptName);
                        // sessionStorage.setItem("drName", drName);
                        // sessionStorage.setItem("operTime", operTime);
                        // sessionStorage.setItem("userName", this.PatientName);
                        // sessionStorage.setItem("sumfee", sumfee);
                        // sessionStorage.setItem("itemName", itemName);
                        Toast("缴费成功，请注意查看缴费情况");
                        window.location.href = "/FWait";
                      }
                      //window.location.href = "/WechatPay/Result?returnurl="+encodeURIComponent("@ViewData["returnurl"]")+"&oid=" + '@ViewData["oid"]' + "&state=" + /\w+$/.exec(res.errMsg)[0];
                    },
                    fail: async function (res) {
                      // Toast.clear();
                      if (res) {
                        this.disabled = false;
                        Toast("您已取消缴费");
                      }
                      console.log("fail");
                    },
                    cancel: async function (res) {
                      // Toast.clear();
                      if (res) {
                        this.disabled = false;
                        Toast("您已取消缴费");
                      }
                      console.log("cancel");
                    },
                  });
                } else if (res.data.result === 1) {
                  Toast.clear();
                  Toast("您已缴费成功，请及时查看缴费情况");
                } else {
                  Toast.clear();
                  Toast("缴费失败，系统异常" + res.data.result);
                }
              })
              .catch(() => {
                Toast.clear();
                // Toast("出现未知错误：" + err);
              });
          } else {
            Toast("创建订单失败");
          }
          // console.log(orderid);
        })
        .catch(() => {
          // 更新orderid
        });
    },
    async getWxPayInfo() {
      let me = this;
      //const query = $route;  // 从Url上获取订单Id
      //me.orderId = query.orderId;
      me.orderId = orderid;
      // console.log("123456");
      console.log("orderid是" + me.orderId);
      await ajax
        .post("/Api/PayService/CreatePay", {
          orderId: orderid,
          payWay: "微信公众号",
          operatorId: OperatorId,
        })
        .then((rep) => {
          let res = rep.data.result;
          console.log("res是：" + JSON.stringify(res));
          if (res.result === 1) {
            let dat = JSON.parse(res.value);
            // alert("dat是"+ JSON.stringify(dat));
            // me.wxPayInfo.appId = dat.AppId;
            me.wxPayInfo.timestamp = dat.TimeStamp;
            me.wxPayInfo.nonceStr = dat.NonceStr;
            me.wxPayInfo.package = dat.Package;
            me.wxPayInfo.signType = dat.SignType;
            me.wxPayInfo.paySign = dat.PaySign;
            // console.log("公众号Id：" + me.wxPayInfo.appId);
            console.log("时间戳" + me.wxPayInfo.timestamp);
            console.log("随机字符串" + me.wxPayInfo.nonceStr);
            console.log("订单详情拓展字符串" + me.wxPayInfo.package);
            console.log("签名方式" + me.wxPayInfo.signType);
            console.log("签名" + me.wxPayInfo.paySign);
          } else {
            Dialog.alert({
              message: "签名验证失败",
            }).then(() => {
              //
            });
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    onToast() {
      Toast("正在测试中");
    },
  },
};
</script>

<style></style>
