var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-cell',{attrs:{"title":_vm.PatientName,"is-link":"","to":"/FCardL","value":"切换用户"}},[_c('van-icon',{staticClass:"iconfont",attrs:{"slot":"right-icon","class-prefix":"icon","name":"qiehuan","color":"#57c4b7"},slot:"right-icon"})],1),_c('van-list',{attrs:{"finished":_vm.finished},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('van-cell',{attrs:{"title":"待缴费订单"}},[_c('template',{slot:"default"},[_vm._v(" 总金额： "),_c('span',{staticStyle:{"color":"#57c4b7"}},[_vm._v("¥"+_vm._s(_vm.FeeSum))])])],2),_vm._l((_vm.lists),function(item){return _c('van-cell-group',{key:item + 'payl'},[_c('van-cell',_vm._b({on:{"click":function($event){return _vm.onclickOrder(item.regNo, item.seqNos)}}},'van-cell',item,false),[_c('template',{slot:"title"},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.deptName))]),_c('span',{staticStyle:{"color":"#57c4b7"}},[_vm._v("|")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.drName))]),_c('span',{staticStyle:{"color":"#57c4b7"}},[_vm._v("|")]),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v("未支付")])]),_c('template',{slot:"label"},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("就诊时间：")]),_c('span',{staticStyle:{"color":"#57c4b7","font-size":"14px"}},[_vm._v(_vm._s(item.operTime))])]),_c('template',{slot:"right-icon"},[_c('span',{staticStyle:{"color":"#57c4b7","text-align":"center"}},[_c('p',[_vm._v("¥"+_vm._s(item.totalFee))]),_c('van-button',{staticStyle:{"background":"#57c4b7","display":"block","color":"white","float":"right"},attrs:{"round":"","disabled":_vm.disabled,"size":"small","text":"立即支付"},on:{"click":function($event){return _vm.onclickPay(
                  item.regNo,
                  item.deptName,
                  item.drName,
                  item.recipes,
                  item.totalFee,
                  item.content
                )}}})],1)])],2)],1)})],2),(!_vm.lists)?_c('div',{staticStyle:{"margin":"20px 0 25px 0","font-size":"14px","width":"100%","text-align":"center","color":"#969799"}},[_vm._v(" 目前没有代缴费订单哦！ ")]):_vm._e(),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"12px","color":"#d3d3d3","margin":"1% 40%","width":"auto","height":"40px"}},[_c('span',[_vm._v("©拓实银医")])])
}]

export { render, staticRenderFns }